import { type Request } from './Request';
import { type Notification } from '../types';
import { ServerRoutePaths } from '@shared/utils';

export class NotificationsApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getNotifications(params: {
    userId: string;
    limit?: number;
    nextToken?: string;
    ascending?: boolean;
  }) {
    return this.request.call<{
      data: Notification[];
      nextToken?: string | undefined;
      count: number;
    }>({
      url: ServerRoutePaths.NOTIFICATIONS,
      params
    });
  }

  public updateNotification(data: { id: string; user: { id: string }; viewed: boolean }) {
    return this.request.call<Notification>({
      url: ServerRoutePaths.NOTIFICATIONS,
      method: 'PUT',
      data
    });
  }

  public deleteNotification(params: { notificationId: string; userId: string }) {
    return this.request.call<Notification>({
      url: ServerRoutePaths.NOTIFICATIONS,
      method: 'DELETE',
      params
    });
  }
}
