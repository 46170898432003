import { ServerRoutePaths } from '@shared/utils';
import { type School } from '../types';
import { type Request } from './Request';

export class SchoolApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getSchools() {
    return this.request.call<School[]>({ url: ServerRoutePaths.SCHOOLS });
  }

  public getSchoolById({ id }: { id: string }) {
    return this.request.call<School>({ url: `${ServerRoutePaths.SCHOOLS}/${id}` });
  }
}
