import { type FC, type PropsWithChildren, useRef } from 'react';

import { useOutsideClick } from '@shared/hooks';

import { type ModalElProps } from '../types';
import ModalOverlay from './ModalOverlay';

export const ModalEl: FC<PropsWithChildren<ModalElProps>> = ({
  children,
  onClose,
  closeOnOutsideClick,
  className,
  includeInnerBody = true
}) => {
  const innerRef = useRef(null);

  useOutsideClick(innerRef, () => (onClose && closeOnOutsideClick ? onClose() : null));

  if (!includeInnerBody) {
    return <ModalOverlay className={className}>{children}</ModalOverlay>;
  }

  return (
    <ModalOverlay className={className}>
      <div ref={innerRef}>{children}</div>
    </ModalOverlay>
  );
};
