import { type FC } from 'react';
import clsx from 'clsx';

import { Exaclamation, CheckCircle } from '@shared/icons';

import { type HistoryBadgeProps } from '../types';

const commonClasses =
  'copy-3 flex items-center justify-center w-[7.875rem] px-2 gap-1 h-7 rounded w-auto p-1 mr-2.5';
const content = {
  OK: {
    icon: <CheckCircle stroke="#00602D" />,
    text: 'Completed',
    className: clsx('bg-[#ECFDF5] text-secondary-success', commonClasses)
  },
  NOT_COMPLETED: {
    icon: <Exaclamation stroke="#FF4D00" />,
    text: 'Incomplete',
    className: clsx('bg-[#FFF7ED] text-secondary-warning', commonClasses)
  }
};

export const HistoryBadge: FC<HistoryBadgeProps> = ({ isComplete }) => {
  const { icon, text, className } = isComplete ? content.OK : content.NOT_COMPLETED;

  return (
    <span className={className}>
      {icon}
      <span className="hidden">{text}</span>
    </span>
  );
};
