import {
  type FC,
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useCallback
} from 'react';

type CalendarContextType = {
  showModal: boolean;
  toggleModal: () => void;
};

export const CalendarContext = createContext<CalendarContextType>({
  showModal: false,
  toggleModal: () => null
});

export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showModal, setShowModal] = useState(true);

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, [setShowModal]);

  return (
    <CalendarContext.Provider value={{ showModal, toggleModal }}>
      {children}
    </CalendarContext.Provider>
  );
};
