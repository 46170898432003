import { useEffect, type MutableRefObject } from 'react';

export const useOutsideClick = (
  elRef: MutableRefObject<HTMLElement | null>,
  onClick: () => void
) => {
  useEffect(() => {
    const onCloseEvent = (e: MouseEvent) => {
      if (elRef.current && e.target && !elRef.current.contains(e.target as Node)) {
        onClick();
      }
    };

    document.addEventListener('mousedown', onCloseEvent);

    return () => {
      document.removeEventListener('mousedown', onCloseEvent);
    };
  });
};
