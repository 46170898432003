import { useStudent } from '@student';

export type StudentGrade = {
  grade: string | null;
  is2GradeOrBelow: boolean;
  is3GradeOrBelow: boolean;
  is3to5Grade: boolean;
  is5GradeOrBelow: boolean;
  is8GradeOrBelow: boolean;
  is9GradeOrAbove: boolean;
  is6to12Grade: boolean;
  is9to12Grade: boolean;
};

export const NO_GRADE: StudentGrade = {
  grade: null,
  is2GradeOrBelow: false,
  is3GradeOrBelow: false,
  is3to5Grade: false,
  is5GradeOrBelow: false,
  is8GradeOrBelow: false,
  is9GradeOrAbove: false,
  is6to12Grade: false,
  is9to12Grade: false
};
export const useStudentGrade = (): StudentGrade => {
  const studentContext = useStudent();
  const studentGrade = studentContext.student?.grade;
  if (!studentGrade) {
    return NO_GRADE;
  }

  //TODO Find out if there's a more flexible way to do this.
  return {
    grade: studentGrade,
    is2GradeOrBelow: isTKorK(studentGrade) || isGradeBelowInclusive(studentGrade, 2),
    is3GradeOrBelow: isTKorK(studentGrade) || isGradeBelowInclusive(studentGrade, 3),
    is3to5Grade: isGradeBetweenInclusive(studentGrade, 3, 5),
    is5GradeOrBelow: isTKorK(studentGrade) || isGradeBelowInclusive(studentGrade, 5),
    is8GradeOrBelow: isTKorK(studentGrade) || isGradeBelowInclusive(studentGrade, 8),
    is9GradeOrAbove: isGradeAboveInclusive(studentGrade, 9),
    is6to12Grade: isGradeBetweenInclusive(studentGrade, 6, 12),
    is9to12Grade: isGradeBetweenInclusive(studentGrade, 9, 12)
  };
};

function isTKorK(grade: string) {
  return ['TK', 'K'].includes(grade);
}

function isGradeBelowInclusive(grade: string, num: number) {
  const numberGrade = Number(grade);
  return !isNaN(numberGrade) && numberGrade <= num;
}

function isGradeBetweenInclusive(grade: string, num1: number, num2: number) {
  const numberGrade = Number(grade);
  return !isNaN(numberGrade) && numberGrade >= num1 && numberGrade <= num2;
}

function isGradeAboveInclusive(grade: string, num: number) {
  const numberGrade = Number(grade);
  return !isNaN(numberGrade) && numberGrade >= num;
}
