import { Navigate, Outlet } from 'react-router-dom';
import { useHomePage, useIsGuardian, useStudentGrade } from '@shared/hooks';

export const ProtectedGuardianAnd9_12StudentRoute = () => {
  const isGuardian = useIsGuardian();
  const studentGrade = useStudentGrade();
  const homePage = useHomePage();

  if (isGuardian || studentGrade.is9to12Grade) {
    return <Outlet />;
  }
  return <Navigate to={homePage} />;
};

export default ProtectedGuardianAnd9_12StudentRoute;
