import {
  type FC,
  type PropsWithChildren,
  useState,
  useEffect,
  createContext,
  useCallback
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';

import {
  StudentApiResponse,
  StudentsApiResponse,
  StudentWithInternalId,
  User
} from '@shared/types';
import { useAuth } from '@authentication';
import { useRequest } from '@request';
import { RoutePaths } from '@shared/utils';
import type { ActivityStatus } from '@activities';

export type StudentContextType = {
  students: StudentWithInternalId[];
  student: StudentWithInternalId | null;
  setStudent: (studentId: StudentWithInternalId) => void;
  setStudents: (students: StudentWithInternalId[]) => void;
  buildStudentUrlQuery: (student: StudentWithInternalId) => string;
  loading: boolean;
  getGuardianStudent: () => Promise<void>;
  updateActivitiesStatus: (activitiesStatus: ActivityStatus[]) => Promise<void>;
};

export const StudentContext = createContext<StudentContextType>({
  students: [],
  student: null,
  setStudent: () => null,
  setStudents: () => null,
  buildStudentUrlQuery: () => '',
  loading: false,
  getGuardianStudent: () => Promise.resolve(),
  updateActivitiesStatus: () => Promise.resolve()
});
export const StudentUrlQueryName = 's';
const buildStudentUrlQuery = (student: StudentWithInternalId) => {
  const idQuery = student.internalId ? String(student.internalId) : '';
  return `?${StudentUrlQueryName}=${encodeURIComponent(idQuery)}`;
};

const generateInternalId = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
  return [firstName, lastName].join('|');
};

export const StudentProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [student, setStudent] = useState<StudentWithInternalId | null>(null);
  const [students, setStudents] = useState<StudentWithInternalId[]>([]);
  const navigate = useNavigate();
  const {
    student: { api: studentApi },
    user: { api: userApi }
  } = useRequest();
  const [loading, setLoading] = useState(false);

  const { user, isLoggedIn, logout } = useAuth();

  const fetchStudent = useCallback(
    async (user: User) => {
      if (!user.firstName || !user.lastName) {
        throw new Error('User is missing names!');
      }

      const student = (await studentApi.getStudentByEmail({
        email: user.email as string
      })) as unknown as StudentApiResponse;

      if (!student) {
        throw new Error('Student not found!');
      }
      const studentWithInternalId = {
        ...student.data,
        internalId: generateInternalId({
          firstName: String(user.firstName),
          lastName: String(user.lastName)
        })
      };

      if (studentWithInternalId) {
        setStudent(studentWithInternalId);
      }
    },
    [studentApi]
  );

  const getGuardianStudent = useCallback(async () => {
    try {
      setLoading(true);
      const studentId = searchParams.get(StudentUrlQueryName);
      const student = students.find((student) => student.internalId === studentId);
      if (student) {
        setStudent(student);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [students, searchParams]);

  const fetchGuardianStudents = useCallback(async () => {
    try {
      setLoading(true);
      const jsonResponse = (await studentApi.getStudents()).data as unknown as StudentsApiResponse;
      const studentsWithInternalId = jsonResponse.data.map((student) => ({
        ...student,
        internalId: generateInternalId({
          firstName: student.first_name,
          lastName: student.last_name
        })
      }));

      setStudents(studentsWithInternalId);
    } catch (err) {
      console.error(err);
      if (isAxiosError(err)) {
        const error_code = err.response?.data?.error?.error_code;
        const errorRoute = '/error';
        const redirect_url = RoutePaths.SIGN_IN;
        if (['accountNotFound', 'schoolRestricted', 'serverError'].includes(error_code)) {
          // account not found is user cannot be found in IDP
          // account not found is also for users in dev/stg that does not pass the regex users
          // school restricted is student is not in an enabled school
          const errorPageRoute = location.search ? `${errorRoute}${location.search}` : errorRoute;
          await logout(false);
          navigate(errorPageRoute, { state: { error_code, redirect_url } });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [studentApi, navigate, location, logout]);

  const updateActivitiesStatus = useCallback(
    async (activitiesStatus: ActivityStatus[]) => {
      const updatedStudent = {
        ...student,
        activitiesStatus
      } as StudentWithInternalId;

      setStudent(updatedStudent);
      if (student?.student_id) {
        await userApi.saveActivitiesStatus({ userId: student.student_id, activitiesStatus });
      }
    },
    [student, userApi]
  );

  useEffect(() => {
    if (isLoggedIn && user && user.type === 'guardian' && !students.length) {
      fetchGuardianStudents().catch(console.error);
    }
  }, [isLoggedIn, user, fetchGuardianStudents, students.length]);

  useEffect(() => {
    if (isLoggedIn && user && user.type === 'student' && !student) {
      fetchStudent(user).catch(console.error);
    }
  }, [isLoggedIn, user, fetchStudent, student]);

  return (
    <StudentContext.Provider
      value={{
        students,
        student,
        setStudent,
        setStudents,
        buildStudentUrlQuery,
        loading,
        getGuardianStudent,
        updateActivitiesStatus
      }}>
      {children}
    </StudentContext.Provider>
  );
};
