import React from 'react';

import Ed from '@assets/Ed.png';

// Define the prop types
type EdLogoWelcomeProps = {
  className?: string; // Optional class name for the <svg> element
};

export const EdLogoWelcome: React.FC<EdLogoWelcomeProps> = ({ className }: EdLogoWelcomeProps) => {
  return <img className={className} src={Ed} alt="Ed" />;
};

export default EdLogoWelcome;
