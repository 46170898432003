export enum ENV {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  DEMO = 'demo',
  PROD = 'prod'
}

export const HOST_PROD = 'mobile.app.lausd.iap.allhere.co';
export const HOST_STAGING = 'mobile.app.staging.lausd.iap.allhere.co';
export const HOST_DEMO = 'mobile.app.demo.lausd.iap.allhere.co';
export const HOST_DEV = 'mobile.app.dev.lausd.iap.allhere.co';

export const ENV_MAP = {
  [HOST_PROD]: ENV.PROD,
  [HOST_STAGING]: ENV.STAGING,
  [HOST_DEMO]: ENV.DEMO,
  [HOST_DEV]: ENV.DEV
};

export function getEnv(): ENV {
  const url = window.location.hostname as keyof typeof ENV_MAP;

  return ENV_MAP[url] || ENV.LOCAL;
}
