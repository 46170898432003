import type { ActivityStatus } from '@activities';
import { UserPreferences } from '@shared/types';
import { ServerRoutePaths } from '@shared/utils';
import { ApiResponse } from '../types';
import { type Request } from './Request';

export class UserApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getActivitiesStatus() {
    return this.request.call<ApiResponse<ActivityStatus[]>>({
      method: 'GET',
      url: ServerRoutePaths.ACTIVITY_USAGE
    });
  }

  public saveActivitiesStatus(data: { userId: string; activitiesStatus: ActivityStatus[] }) {
    return this.request.call<void>({
      method: 'POST',
      url: ServerRoutePaths.ACTIVITY_USAGE,
      data
    });
  }
  public updateSettings(data: UserPreferences) {
    return this.request.call<void>({
      method: 'PUT',
      url: ServerRoutePaths.SETTINGS,
      data
    });
  }
}
