import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks';
import { useStudent } from '@student';
import { RoutePaths } from '@shared/utils';

export const RedirectAuthenticatedRoute = () => {
  const { isLoggedIn, user } = useAuth();
  const { buildStudentUrlQuery, student } = useStudent();

  if (!isLoggedIn || !user) {
    return <Outlet />;
  }

  if (user.type === 'guardian') {
    return <Navigate to={RoutePaths.STUDENT_SELECTOR} />;
  } else if (user.type === 'student' && student) {
    // it takes a second for the student to be set in the context
    return (
      <Navigate
        to={{
          pathname: RoutePaths.STUDENT_HOME,
          search: buildStudentUrlQuery(student)
        }}
      />
    );
  }
  return null;
};

export default RedirectAuthenticatedRoute;
