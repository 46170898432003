import { ENV, getEnv } from '@shared/utils';

const env = getEnv();

function getFilesEnv() {
  if (env === ENV.PROD) {
    return '.';
  }
  return '.dev.';
}
const filesEnv = getFilesEnv();
const edBackground = `https://files${filesEnv}lausd.iap.allhere.co/images/ed-background/background.jpg`;

export const useEdBackground = () => {
  // return the same reference for rerenders
  return edBackground;
};
