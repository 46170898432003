import { type FC, useRef, useState } from 'react';
import clsx from 'clsx';

import { useOutsideClick } from '@shared/hooks';
import { DropdownButton } from './DropdownButton';
import { DropdownList } from './DropdownList';
import { DropdownItem } from './DropdownItem';
import { type DropdownProps, type DropdownOption } from '../types';

export const Dropdown: FC<DropdownProps> = ({
  placeholder,
  isOpen,
  isSelect,
  onClose,
  onOpen,
  options,
  width
}) => {
  const [buttonText, setButtonText] = useState(placeholder);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onClickItem =
    ({ label, onClick }: DropdownOption) =>
    () => {
      if (isSelect) {
        setButtonText(label);
      }

      onClick();
    };

  useOutsideClick(wrapperRef, onClose);

  return (
    <div className="relative inline-block text-left" ref={wrapperRef}>
      <DropdownButton width={width} onClick={onOpen} isOpen={isOpen}>
        {buttonText}
      </DropdownButton>
      <DropdownList className={clsx({ 'animate-fade-in': isOpen, 'animate-fade-out': !isOpen })}>
        {options.map((o) => (
          <DropdownItem key={o.label} label={o.label} onClick={onClickItem(o)} />
        ))}
      </DropdownList>
    </div>
  );
};
