import { useContext } from 'react';

import { RequestContext } from '../RequestContext';

export const useRequest = () => {
  const context = useContext(RequestContext);

  if (!context) {
    throw new Error('No Request Provider');
  }

  return context;
};
