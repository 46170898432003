import { useCallback } from 'react';
import { useI18n } from '@i18n';
import { useAuth } from '@authentication';
import { Language } from '../types/language';

export const useChangeUserLang = () => {
  const { changeLanguage } = useI18n();
  const { user, updatePreferences } = useAuth();
  const changeUserLang = useCallback(
    async (lng: Language) => {
      await changeLanguage(lng);

      if (user) {
        const preferences = {
          ...user.preferences,
          language: lng
        };
        updatePreferences(preferences);
      }
    },
    [updatePreferences, user, changeLanguage]
  );

  return { changeUserLang };
};
