import { useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import StudentIcon from '@assets/backpack.svg';
import GuardianIcon from '@assets/house.png';
import { useRequest } from '@request';
import { useDefaultLanguage, useLocationSearch } from '@shared/hooks';
import { RoutePaths } from '@shared/utils';

import { Persona } from './Persona';
import { useI18n } from '../../i18n';

export function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, changeLanguage } = useI18n();
  const defaultLanguage = useDefaultLanguage();
  const {
    auth: { api: authApi }
  } = useRequest();

  const handleStudentAuthentication = useCallback(async () => {
    try {
      const { data: response } = await authApi.samlAuthorize({
        relayState: `${window.location.protocol}//${window.location.host}${RoutePaths.AUTH_SAML_CALLBACK}`
      });
      // this will redirect the user to the IDP login page
      window.location.href = response.redirect_url;
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Callback error:', error);
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 500 &&
        error.response?.data?.error.error_code === 'serverError'
      ) {
        const errorRoute = '/error';
        const redirectRoute = location.search ? `${errorRoute}${location.search}` : errorRoute;
        navigate(redirectRoute, {
          state: {
            error_code: error.response.data.error.error_code,
            redirect_url: RoutePaths.SIGN_IN
          }
        });
        return;
      } else {
        // throw new Error('unhandled student authentication error');
      }
    }
  }, [authApi, location.search, navigate]);

  useEffect(() => {
    changeLanguage(defaultLanguage).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleParentAuthentication = useCallback(() => {
    // pass the querystring to the login page
    navigate(`${RoutePaths.GUARDIAN_LOGIN}${location.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // TODO: update the type definitions for the t function to be either a string or an array of strings if the returnObjects option is set to true
  const translations = t('signIn.disclaimer', { returnObjects: true }) as unknown;
  const route = useLocationSearch(RoutePaths.TERMS_AND_CONDITIONS);

  return (
    <>
      <header className="mt-5 md:mt-10">
        <h1 className="text-center font-poppins text-lg font-semibold leading-9 text-secondary-yield md:text-[2rem]">
          {t('signIn.title')}
        </h1>
      </header>
      <h3 className="mt-[0.875rem] w-full max-w-xs px-4 text-center font-poppins text-2xl font-semibold leading-7 text-secondary-white sm:max-w-md md:mt-7 md:max-w-lg md:px-0 md:text-[2rem] md:leading-[2.625rem]">
        {t('signIn.subtitle')}
      </h3>

      <div className="mt-7 flex flex-row items-center justify-center gap-6 md:mt-14">
        {/* this should be a map but with two components, keep it simple for now */}
        <Persona
          className=""
          id="student"
          icon={StudentIcon}
          onClick={handleStudentAuthentication}
          name={t('signIn.buttons.student')}
        />
        <Persona
          className=""
          id="guardian"
          icon={GuardianIcon}
          onClick={handleParentAuthentication}
          name={t('signIn.buttons.guardian')}
        />
      </div>
      <div
        id="disclaimer"
        className="sm:max-w-lg-md mt-[1.875rem] flex max-w-md flex-col items-center md:max-w-xl">
        <div className="px-10 sm:px-0">
          {Array.isArray(translations)
            ? translations.map((translation, index) =>
                index === translations.length - 1 ? (
                  <div className="inline" key={translation.slice(10)}>
                    <span className="font-lato text-[0.8rem] leading-5 text-secondary-white">
                      {' '}
                    </span>
                    <Link
                      to={route}
                      className="inline border-b border-secondary-white pb-0 font-lato text-[0.8rem] font-medium leading-5 text-secondary-white">
                      {translation}.
                    </Link>
                  </div>
                ) : (
                  <span
                    key={translation.slice(0, 10)}
                    className="inline font-lato text-[0.8rem] font-medium leading-5 text-secondary-white">
                    {translation}
                  </span>
                )
              )
            : null}
        </div>
      </div>
    </>
  );
}

export default SignIn;
