import { type FC, type SVGProps } from 'react';

export const CheckCircle: FC<SVGProps<SVGSVGElement & SVGPathElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      {...props}
    />
  </svg>
);
