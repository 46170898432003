import { AxiosResponse } from 'axios';
import { type Request } from './Request';
import { type SamlAuthorizeResponse } from '../types';
import { type OauthLoginPayload } from '../types/OauthLoginPayload';
import { type OauthLoginResponse } from '../types/OauthLoginResponse';
import { type SamlAuthorizePayload } from '../types/SamlAuthorizePayload';
import { ServerRoutePaths } from '@shared/utils';

export class AuthenticationApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  // lambda is commented out
  // public logout() {
  //   return this.request.call({
  //     method: 'post',
  //     url: RoutePaths.LOGOUT
  //   });
  // }

  public oAuthLogin(data: OauthLoginPayload): Promise<AxiosResponse<OauthLoginResponse>> {
    return this.request.call({
      method: 'post',
      url: ServerRoutePaths.GUARDIAN_LOGIN,
      data
    });
  }

  public samlAuthorize(data: SamlAuthorizePayload): Promise<AxiosResponse<SamlAuthorizeResponse>> {
    return this.request.call({
      method: 'post',
      url: ServerRoutePaths.STUDENT_LOGIN,
      data
    });
  }

  public getUser() {
    return this.request.call({ url: ServerRoutePaths.USER });
  }
}
