import { type FC } from 'react';

import { useDidMount, useCleanLogout } from '@shared/hooks';

export const SignOut: FC = () => {
  const logout = useCleanLogout();

  useDidMount(() => {
    logout();
  });

  return null;
};

export default SignOut;
