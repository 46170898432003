import { useAuth } from '@authentication';
import { useStudent } from '@student';

export const useIsChatEligible = () => {
  const { user, isLoggedIn } = useAuth();
  const { student } = useStudent();

  return (
    isLoggedIn &&
    user &&
    (user.type === 'guardian' || (user.type === 'student' && student && student.can_chat))
  );
};
