import { useCallback } from 'react';
import { useAuth } from '@authentication';
import { useStudent } from '@student';

export const useCleanLogout = () => {
  const auth = useAuth();
  const studentContext = useStudent();

  const logout = useCallback(async () => {
    if (auth?.user?.type === 'guardian') {
      studentContext?.setStudents([]);
    }
    await auth.logout();
  }, [auth, studentContext]);

  return logout;
};
