import { Cookies } from 'react-cookie';
import axios from 'axios';

import { ServerRoutePaths } from './server-route-paths';

const cookies = new Cookies();

const endpoint = import.meta.env.VITE_API_BASE_URL;

const userEndpoint = `${endpoint}${ServerRoutePaths.USER}`;

export const initApp = async (idToken = cookies.get('id_token')) => {
  try {
    if (idToken) {
      const response = await axios.get(userEndpoint, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'X-Platform': 'web'
        }
      });
      if (response?.data) {
        return {
          user: response.data,
          isLoggedIn: true
        };
      }
    }
  } catch (error) {
    console.error((error as Error).message);
  }
  return {
    user: null,
    isLoggedIn: false
  };
};
