export enum ServerRoutePaths {
  ACTIVITY_USAGE = '/activity-usage',
  SETTINGS = '/me/settings',
  GUARDIAN_LOGIN = '/auth/oauth/login',
  STUDENT_LOGIN = '/auth/saml/authorize',
  USER = '/auth/user',
  GUARDIANS = '/guardians',
  STUDENTS = '/students',
  STUDENT_BY_EMAIL = '/students/get-by-email',
  SCHOOLS = '/schools',
  RESOURCES = '/resources',
  NOTIFICATIONS = '/notifications',
  TOOl = '/lti/tools',
  AUTHORIZE = '/lti/launch/tenants',
  JWK = '/lti/jwk'
}
