import { ServerRoutePaths } from '@shared/utils';
import { type Request } from './Request';
import {
  GetJWKListResponse,
  GetToolConfigProductListResponse,
  GetToolConfigListResponse,
  PostToolConfigResponse,
  DeleteToolConfigByIdResponse,
  UpdateToolConfigByIdResponse,
  GetToolConfigByIdResponse,
  GetAuthorizationResponse,
  GetToolConfigByProductIdResponse
} from 'src/lti/types/ltiTool';
import { ApiResponse } from '../types';

export class LtiToolApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getToolConfigList({ tenant_id }: { tenant_id: string }) {
    return this.request.call<ApiResponse<GetToolConfigListResponse>>({
      method: 'GET',
      url: `${ServerRoutePaths.TOOl}/tenants/${tenant_id}/configs`
    });
  }

  public getToolConfigById({ config_id, tenant_id }: { config_id: string; tenant_id: string }) {
    return this.request.call<ApiResponse<GetToolConfigByIdResponse>>({
      method: 'GET',
      url: `${ServerRoutePaths.TOOl}/tenants/${tenant_id}/configs/${config_id}`
    });
  }

  public deleteToolConfigById({ config_id, tenant_id }: { config_id: string; tenant_id: string }) {
    return this.request.call<ApiResponse<DeleteToolConfigByIdResponse>>({
      method: 'DELETE',
      url: `${ServerRoutePaths.TOOl}/tenants/${tenant_id}/configs/${config_id}`
    });
  }

  public updateToolConfigById({
    dataToUpdate,
    tenant_id,
    config_id
  }: {
    dataToUpdate: object | null;
    tenant_id: string;
    config_id?: string | null;
  }) {
    return this.request.call<ApiResponse<UpdateToolConfigByIdResponse>>({
      method: 'PUT',
      url: `${ServerRoutePaths.TOOl}/tenants/${tenant_id}/configs/${config_id}`,
      data: dataToUpdate
    });
  }

  public createToolConfig({
    formDataToSave,
    tenant_id
  }: {
    formDataToSave: object;
    tenant_id: string;
  }) {
    return this.request.call<ApiResponse<PostToolConfigResponse>>({
      method: 'POST',
      url: `${ServerRoutePaths.TOOl}/tenants/${tenant_id}/configs`,
      data: formDataToSave,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getToolConfigProductList() {
    return this.request.call<ApiResponse<GetToolConfigProductListResponse>>({
      method: 'GET',
      url: `${ServerRoutePaths.TOOl}/products`
    });
  }

  public getAuthorization(
    { tenant_id }: { tenant_id: string },
    {
      response_type,
      response_mode,
      prompt,
      client_id,
      redirect_uri,
      state,
      nonce,
      login_hint,
      user_id,
      course_id,
      title,
      course_launch_link
    }: {
      response_type: string;
      response_mode: string;
      prompt: string;
      client_id: string;
      redirect_uri: string;
      state: string;
      nonce: string;
      login_hint: string;
      user_id: string;
      course_id: string;
      title: string;
      course_launch_link: string;
    }
  ) {
    return this.request.call<GetAuthorizationResponse>({
      method: 'GET',
      url: `${ServerRoutePaths.AUTHORIZE}/${tenant_id}/authorize`,
      params: {
        response_type,
        response_mode,
        prompt,
        client_id,
        redirect_uri,
        state,
        nonce,
        login_hint,
        user_id,
        course_id,
        title,
        course_launch_link
      }
    });
  }

  public getToolConfigProductsByProductId({ product_id }: { product_id: string }) {
    return this.request.call<ApiResponse<GetToolConfigByProductIdResponse>>({
      method: 'GET',
      url: `${ServerRoutePaths.TOOl}/product/${product_id}/configs`
    });
  }

  public getJwkList() {
    return this.request.call<GetJWKListResponse>({
      method: 'GET',
      url: `${ServerRoutePaths.JWK}`
    });
  }
}
