export enum RoutePaths {
  AUTH_OAUTH_CALLBACK = '/auth/oauth/callback',
  AUTH_SAML_CALLBACK = '/auth/saml/callback',
  AVATAR_SELECTOR = '/avatar-selector',
  ERROR = '/error',
  GUARDIAN_HOME = '/guardian/home',
  GUARDIAN_LOGIN = '/guardian/login',
  HOME = '/',
  LOGOUT = '/logout',
  LTI_AUTHORIZE = '/lti/authorize',
  LTI_SETTINGS = '/lti/settings',
  LTI_TOOLS_LIST = '/lti/tools',
  LTI_JWK = '/lti/jwk',
  MESSAGES = '/messages',
  NOTIFICATIONS = '/notifications',
  RESOURCES = '/resources',
  SETTINGS = '/settings',
  SIGN_IN = '/sign-in',
  SPEECH_TO_TEXT = '/speech-to-text-demo',
  SSO = '/ssotest',
  STUDENT_COLLEGE_AND_CAREER_READINESS = '/student/college-and-career-readiness',
  STUDENT_HOME = '/student/home',
  STUDENT_PROGRESS_HISTORY = '/student/progress-history',
  STUDENT_SELECTOR = '/student/selector',
  TERMS_AND_CONDITIONS = '/terms-and-conditions'
}
