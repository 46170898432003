import { useRef } from 'react';
import { SupportedLanguage } from '../types/language';

export const useSupportedLanguages = (): Array<SupportedLanguage> => {
  // there should be 70 supported languages with 70 unique codes
  const languages = [
    {
      code: 'en',
      nativeName: 'English',
      englishName: 'English'
    },
    {
      code: 'es',
      nativeName: 'Español',
      englishName: 'Spanish'
    },
    {
      code: 'hy',
      nativeName: 'հայերեն',
      englishName: 'Armenian'
    },
    {
      code: 'ru',
      nativeName: 'русский',
      englishName: 'Russian'
    },
    {
      code: 'ko',
      nativeName: '한국어',
      englishName: 'Korean'
    },
    {
      code: 'tl',
      nativeName: 'Filipino/Tagalog',
      englishName: 'Filipino'
    },
    {
      code: 'vi',
      nativeName: 'Tiếng Việt',
      englishName: 'Vietnamese'
    },
    {
      code: 'zh-CN',
      nativeName: '简体字',
      englishName: 'Chinese (simplified)'
    },
    {
      code: 'zh-TW',
      nativeName: '繁體字',
      englishName: 'Cantonese,Mandarin,Toishanese,Chaozhou'
    },
    {
      code: 'bn',
      nativeName: 'বাংলা',
      englishName: 'Bengali'
    },
    {
      code: 'ar',
      nativeName: 'العربية',
      englishName: 'Arabic'
    },
    {
      code: 'ja',
      nativeName: '日本語',
      englishName: 'Japanese'
    },
    {
      code: 'th',
      nativeName: 'ไทย',
      englishName: 'Thai'
    },
    {
      code: 'he',
      nativeName: 'עברית',
      englishName: 'Hebrew'
    },
    {
      code: 'pa',
      nativeName: 'ਪੰਜਾਬੀ',
      englishName: 'Punjabi'
    },
    {
      code: 'mn',
      nativeName: 'монгол',
      englishName: 'Mongolian'
    },
    {
      code: 'ps',
      nativeName: 'پښتو',
      englishName: 'Pashto'
    },
    {
      code: 'pt',
      nativeName: 'português',
      englishName: 'Portuguese'
    },
    {
      code: 'km',
      nativeName: 'ខ្មែរ',
      englishName: 'Cambodian'
    },
    {
      code: 'uk',
      nativeName: 'українська',
      englishName: 'Ukrainian'
    },
    {
      code: 'am',
      nativeName: 'አማርኛ',
      englishName: 'Amharic'
    },
    {
      code: 'fr',
      nativeName: 'français',
      englishName: 'French'
    },
    {
      code: 'hi',
      nativeName: 'हिन्दी',
      englishName: 'Hindi'
    },
    {
      code: 'ur',
      nativeName: 'اردو',
      englishName: 'Urdu'
    },
    {
      code: 'tr',
      nativeName: 'Türkçe',
      englishName: 'Turkish'
    },
    {
      code: 'et',
      nativeName: 'eesti',
      englishName: 'Estonian'
    },
    {
      code: 'id',
      nativeName: 'bahasa Indonesia',
      englishName: 'Indonesian'
    },
    {
      code: 'gu',
      nativeName: 'ગુજરાતી',
      englishName: 'Gujarati'
    },
    {
      code: 'uz',
      nativeName: `o‘zbek`,
      englishName: 'UZBEK'
    },
    {
      code: 'ta',
      nativeName: 'தமிழ்',
      englishName: 'Tamil'
    },
    {
      code: 'ig',
      nativeName: 'Igbo',
      englishName: 'Ibo'
    },
    {
      code: 'sm',
      nativeName: 'Samoan',
      englishName: 'Samoan'
    },
    {
      code: 'si',
      nativeName: 'සිංහල',
      englishName: 'Sinhala; Sinhalese'
    },
    {
      code: 'te',
      nativeName: 'తెలుగు',
      englishName: 'Telugu'
    },
    {
      code: 'ne',
      nativeName: 'नेपाली',
      englishName: 'Nepali'
    },
    {
      code: 'my',
      nativeName: 'မြန်မာ',
      englishName: 'Burmese'
    },
    {
      code: 'de',
      nativeName: 'Deutsch',
      englishName: 'German'
    },
    {
      code: 'it',
      nativeName: 'italiano',
      englishName: 'Italian'
    },
    {
      code: 'ro',
      nativeName: 'română',
      englishName: 'Romanian'
    },
    {
      code: 'kk',
      nativeName: 'қазақ тілі',
      englishName: 'Kazakh'
    },
    {
      code: 'ms',
      nativeName: 'bahasa Malaysia',
      englishName: 'Malay'
    },
    {
      code: 'mr',
      nativeName: 'मराठी',
      englishName: 'Marathi'
    },
    {
      code: 'sq',
      nativeName: 'shqip',
      englishName: 'Albanian'
    },
    {
      code: 'lt',
      nativeName: 'lietuvių',
      englishName: 'Lithuanian'
    },
    {
      code: 'hu',
      nativeName: 'magyar',
      englishName: 'Hungarian'
    },
    {
      code: 'ti',
      nativeName: 'ትግርኛ',
      englishName: 'Tigrinya'
    },
    {
      code: 'ilo',
      nativeName: 'Ilokano',
      englishName: 'Ilocano'
    },
    {
      code: 'yo',
      nativeName: 'Èdè Yorùbá',
      englishName: 'Yoruba'
    },
    {
      code: 'sv',
      nativeName: 'svenska',
      englishName: 'Swedish'
    },
    {
      code: 'ceb',
      nativeName: 'Binisaya',
      englishName: 'Visayan'
    },
    {
      code: 'pl',
      nativeName: 'polski',
      englishName: 'Polish'
    },
    {
      code: 'bg',
      nativeName: 'български',
      englishName: 'Bulgarian'
    },
    {
      code: 'sw',
      nativeName: 'Kiswahili',
      englishName: 'Swahili'
    },
    {
      code: 'el',
      nativeName: 'Ελληνικά',
      englishName: 'Greek'
    },
    {
      code: 'hr',
      nativeName: 'hrvatski',
      englishName: 'Croatian'
    },
    {
      code: 'sr',
      nativeName: 'српски',
      englishName: 'Serbian'
    },
    {
      code: 'ku',
      nativeName: 'kurdî',
      englishName: 'Kurdish'
    },
    {
      code: 'ka',
      nativeName: 'ქართული',
      englishName: 'Georgian'
    },
    {
      code: 'af',
      nativeName: 'Afrikaans',
      englishName: 'Afrikaans'
    },
    {
      code: 'lo',
      nativeName: 'ລາວ',
      englishName: 'Lao'
    },
    {
      code: 'lv',
      nativeName: 'latviešu',
      englishName: 'Latvian'
    },
    {
      code: 'ml',
      nativeName: 'മലയാളം',
      englishName: 'Malayalam'
    },
    {
      code: 'bs',
      nativeName: 'bosanski',
      englishName: 'Bosnian'
    },
    {
      code: 'cs',
      nativeName: 'čeština',
      englishName: 'Czech'
    },
    {
      code: 'jv',
      nativeName: 'Jawa',
      englishName: 'Javanese'
    },
    {
      code: 'az',
      nativeName: 'azərbaycan',
      englishName: 'Azerbaijani'
    },
    {
      code: 'so',
      nativeName: 'Soomaali',
      englishName: 'Somali'
    },
    {
      code: 'haw',
      nativeName: `ʻŌlelo Hawaiʻi`,
      englishName: 'Hawaiian'
    },
    {
      code: 'da',
      nativeName: 'dansk',
      englishName: 'Danish'
    },
    {
      code: 'tk',
      nativeName: 'türkmen dili',
      englishName: 'Turkmen'
    }
  ];
  const supportedLanguage = useRef<Array<SupportedLanguage>>(
    languages.sort((a, b) => a.nativeName.localeCompare(b.nativeName)) as SupportedLanguage[]
  );
  return supportedLanguage.current;
};
