import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useEncodeLocationSearch = (parameter = 'from') => {
  const location = useLocation();
  const redirectTo = `?${parameter}=${encodeURIComponent(location.pathname + location.search)}`;
  const [route, setRoute] = useState(redirectTo);
  useEffect(() => {
    if (route !== redirectTo) {
      setRoute(redirectTo);
    }
  }, [route, redirectTo]);

  return route;
};
