import { type FC, type SVGProps } from 'react';

export const Caret: FC<SVGProps<SVGSVGElement & SVGPathElement>> = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29301 7.29289C5.68354 6.90237 6.3167 6.90237 6.70723 7.29289L10.0001 10.5858L13.293 7.29289C13.6835 6.90237 14.3167 6.90237 14.7072 7.29289C15.0977 7.68342 15.0977 8.31658 14.7072 8.70711L10.7072 12.7071C10.3167 13.0976 9.68353 13.0976 9.29301 12.7071L5.29301 8.70711C4.90249 8.31658 4.90249 7.68342 5.29301 7.29289Z"
      fill="#0089FF"
      {...props}
    />
  </svg>
);
