import { FC, PropsWithChildren } from 'react';
import { type ModalOverlayProps } from '../types';
import { cn } from '@shared/utils';

const ModalOverlay: FC<PropsWithChildren<ModalOverlayProps>> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'fixed',
        'bottom-0',
        'left-0',
        'right-0',
        'top-0',
        'z-modals',
        'flex',
        'items-center',
        'justify-center',
        'overflow-auto',
        'bg-secondary-black/[.40]',
        className
      )}>
      {children}
    </div>
  );
};

export default ModalOverlay;
