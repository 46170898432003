import { type FC, type PropsWithChildren, useRef, useCallback } from 'react';

import { DrawerContextApi } from '../types';
import { DrawerContext } from '../context';

export const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const stateRef = useRef<Record<string, DrawerContextApi>>({});
  const register = useCallback((id: string, api: DrawerContextApi) => {
    stateRef.current[id] = api;
  }, []);
  const value = {
    register,
    state: stateRef.current
  };

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};
