import { type FC, type PropsWithChildren } from 'react';
import clsx from 'clsx';

import { type DropdownListProps } from '../types';

export const DropdownList: FC<PropsWithChildren<DropdownListProps>> = ({ children, className }) => (
  <ul
    className={clsx(
      ' absolute right-0 z-10 mt-2 h-48 w-40 origin-top-right overflow-scroll rounded-md border border-solid border-solid-gray bg-secondary-white py-1 shadow',
      className
    )}>
    {children}
  </ul>
);
