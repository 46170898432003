import { type FC, type PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ModalEl } from './ModalEl';
import { type ModalProps } from '../types';

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  closeOnOutsideClick,
  blockScrolling = true,
  className,
  includeInnerBody = true
}) => {
  useEffect(() => {
    if (blockScrolling) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [blockScrolling]);

  return createPortal(
    <ModalEl
      className={className}
      onClose={onClose}
      closeOnOutsideClick={closeOnOutsideClick}
      includeInnerBody={includeInnerBody}>
      {children}
    </ModalEl>,
    document.body
  );
};
