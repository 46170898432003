import './index.css';
import { lazy, Suspense, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AskEdButton } from '@shared/components';
import { useChangeUserLang } from '@shared/hooks';
import { Language } from '@shared/types';
import { RoutePaths } from '@shared/utils';

// Do not lazy load route protection comonents security / basic authentication components
import {
  ProtectedChatRoute,
  ProtectedGuardianRoute,
  ProtectedGuardianAnd9_12StudentRoute,
  ProtectedRoute,
  RedirectAuthenticatedRoute,
  SignOut,
  SignIn,
  useAuth
} from './authentication';
import { LangModal } from './i18n';

// Lazy load the rest of the components
// please keep it in alphabetical order
const AccountSettings = lazy(() => import('./user-profile/AccountSettings'));
const AvatarSelector = lazy(() => import('./user-profile/avatarSelector/AvatarSelector'));
const AuthError = lazy(() => import('./authentication/AuthLayout/AuthError'));
const CleverSSO = lazy(() => import('./CleverSSO'));
const CollegeAndCareerReadinessPage = lazy(
  () => import('./student/studentProgress/collegeAndCareerReadiness/CollegeAndCareerReadinessPage')
);
const GuardianHome = lazy(() => import('./guardian/home/GuardianHome'));
const GuardianLogin = lazy(() => import('./guardian/GuardianLogin'));
const Layout = lazy(() => import('./layout/Layout'));
const LTIAuth = lazy(() => import('./lti/LTIAuth'));
const LTIJwk = lazy(() => import('./lti/LTIJwk'));
const LTIToolList = lazy(() => import('./lti/LTIToolList'));
const LTIToolSettingPage = lazy(() => import('./lti/LTIToolSettingPage'));
const Messages = lazy(() => import('./messages/Messages'));
const Notifications = lazy(() => import('./notifications/Notifications'));
const RebrandedAuthSignin = lazy(() => import('./authentication/AuthLayout/RebrandedAuthSignin'));
const RebrandedAuthSignInWithNamedLogo = lazy(
  () => import('./authentication/AuthLayout/RebrandedAuthSignInWithNamedLogo')
);
const Resources = lazy(() => import('./resources/Resources'));
const SamlCallback = lazy(() => import('./authentication/SamlCallback'));
const SignInStudentLandingSelectorRedirect = lazy(
  () => import('./student/SignInOrStudentLandingSelectorRedirect')
);
const SpeechToTextDemo = lazy(() => import('./demo/SpeechToTextDemo'));
const StudentHome = lazy(() => import('./student/home/StudentHome'));
const StudentProgressHistory = lazy(
  () => import('./student/studentProgressHistory/StudentProgressHistory')
);
const StudentSelector = lazy(() => import('./student/studentSelector/StudentSelector'));
const TermsAndConditions = lazy(() => import('./terms-and-conditions/TermsAndConditions'));

const App = () => {
  const { changeUserLang } = useChangeUserLang();
  const { isLanguageModalOpen, setIsLanguageModalOpen } = useAuth();
  const onSave = useCallback(
    async (lang: Language) => {
      await changeUserLang(lang);
      setIsLanguageModalOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeUserLang]
  );

  return (
    <>
      {isLanguageModalOpen ? <LangModal onSave={onSave} /> : null}
      <Suspense fallback={null}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route index element={<SignInStudentLandingSelectorRedirect />} />
              <Route element={<ProtectedGuardianRoute />}>
                <Route path={RoutePaths.GUARDIAN_HOME} element={<GuardianHome />} />
              </Route>
              <Route
                path={RoutePaths.STUDENT_PROGRESS_HISTORY}
                element={<StudentProgressHistory />}
              />
              <Route path={RoutePaths.NOTIFICATIONS} element={<Notifications />} />
              <Route element={<ProtectedChatRoute />}>
                <Route path={RoutePaths.MESSAGES} element={<Messages />} />
              </Route>
              <Route path={RoutePaths.RESOURCES} element={<Resources />} />
              <Route path={RoutePaths.SETTINGS} element={<AccountSettings />} />
            </Route>
            <Route path={RoutePaths.STUDENT_HOME} element={<StudentHome />} />
            <Route path={RoutePaths.AVATAR_SELECTOR} element={<AvatarSelector />} />
            <Route element={<RebrandedAuthSignInWithNamedLogo />}>
              <Route element={<ProtectedGuardianRoute />}>
                <Route path={RoutePaths.STUDENT_SELECTOR} element={<StudentSelector />} />
              </Route>
            </Route>
            <Route element={<ProtectedGuardianAnd9_12StudentRoute />}>
              <Route
                path={RoutePaths.STUDENT_COLLEGE_AND_CAREER_READINESS}
                element={<CollegeAndCareerReadinessPage />}
              />
            </Route>
            <Route path={RoutePaths.LTI_SETTINGS} element={<LTIToolSettingPage />} />
            <Route path={RoutePaths.LTI_TOOLS_LIST} element={<LTIToolList />} />
          </Route>

          <Route element={<RedirectAuthenticatedRoute />}>
            <Route element={<RebrandedAuthSignin />}>
              <Route path={RoutePaths.GUARDIAN_LOGIN} element={<GuardianLogin />} />
              <Route path={RoutePaths.SIGN_IN} element={<SignIn />} />
            </Route>
          </Route>
          <Route element={<RebrandedAuthSignin />}>
            <Route path={RoutePaths.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
          </Route>
          <Route path={RoutePaths.AUTH_SAML_CALLBACK} element={<SamlCallback />} />
          <Route path={RoutePaths.LOGOUT} element={<SignOut />} />

          <Route path={RoutePaths.SSO} element={<CleverSSO />} />

          <Route path={RoutePaths.LTI_AUTHORIZE} element={<LTIAuth />} />
          <Route path={RoutePaths.LTI_JWK} element={<LTIJwk />} />

          <Route element={<RebrandedAuthSignin />}>
            <Route path={RoutePaths.ERROR} element={<AuthError />} />
          </Route>
          <Route path="*" element={<SignInStudentLandingSelectorRedirect />} />

          {import.meta.env.MODE !== 'production' && (
            <Route path={RoutePaths.SPEECH_TO_TEXT} element={<SpeechToTextDemo />} />
          )}
        </Routes>
      </Suspense>
      <AskEdButton iframeCls="h-1/2" />
    </>
  );
};

export default App;
