import clsx from 'clsx';

export interface Persona {
  className?: string;
  id: string;
  icon: string;
  name: string;
  onClick: () => void;
}
export function Persona({ className, id, icon, name, onClick }: Persona) {
  return (
    <div
      id={id}
      className={clsx(
        'flex h-32 w-28 flex-col items-center justify-center rounded-lg bg-secondary-white p-4 hover:cursor-pointer hover:shadow-icon-hover md:h-40 md:w-40',
        className
      )}>
      <button onClick={onClick}>
        <div className="flex h-20 w-20 shrink-0 flex-col items-center justify-center">
          <img src={icon} className="min-h-0 w-24 min-w-0" aria-label={id} />
        </div>
        <div className="mt-2 self-stretch text-center font-lato text-[1.3125rem] font-semibold leading-5 text-tertiary-gray-1 md:text-2xl">
          {name}
        </div>
      </button>
    </div>
  );
}
