import { RoutePaths } from '../utils/route-paths';
import { useIsGuardian } from './use-is-guardian';

export const useHomePage = () => {
  const isGuardian = useIsGuardian();
  if (isGuardian) {
    return RoutePaths.GUARDIAN_HOME;
  }
  return RoutePaths.STUDENT_HOME;
};
