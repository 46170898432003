import {
  type FC,
  type ReactNode,
  createContext,
  useCallback,
  ReactElement,
  useState,
  useEffect
} from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '@i18n';
import { useRequest } from '@request';
import { useDefaultLanguage } from '@shared/hooks';
import { AuthContextType, User, UserPreferences } from '@shared/types';
import { RoutePaths, initApp } from '@shared/utils';
import datadogClient from '../plugins/datadog-rum';

export const AuthContext = createContext<AuthContextType>({
  isLanguageModalOpen: false,
  setIsLanguageModalOpen: () => null,
  isLoggedIn: false,
  user: null,
  updatePreferences: () => Promise.resolve(),
  login: () => Promise.resolve(null),
  loginWithTokens: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
  setAccessToken: () => null,
  setIdentityToken: () => null
});

interface AuthProviderProps {
  children: ReactNode;
  initialUser: User | null;
  loggedIn: boolean;
}

const cookieOptions = {
  path: '/',
  secure: true,
  maxAge: 14400 // 4 hours in seconds to match the server settings
};

const AuthProvider: FC<AuthProviderProps> = ({ children, initialUser, loggedIn }): ReactElement => {
  const { changeLanguage } = useI18n();
  const navigate = useNavigate();

  const [, setCookie, removeCookie] = useCookies(['access_token', 'id_token', 'user']);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(loggedIn);
  const [user, setUser] = useState<User | null>(initialUser);
  const defaultLanguage = useDefaultLanguage();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  useEffect(() => (user ? datadogClient.setUser(user) : datadogClient.removeUser()), [user]);

  const {
    auth: { api: authApi },
    user: { api: userApi }
  } = useRequest();

  const setUserToken = useCallback((user: User) => {
    setCookie('user', user, cookieOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAccessToken = useCallback(
    (token: string) => {
      setCookie('access_token', token, cookieOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setIdentityToken = useCallback(
    (token: string) => {
      setCookie('id_token', token, cookieOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(
    async (redirect = true) => {
      setUser(null);
      removeCookie('access_token', cookieOptions);
      removeCookie('id_token', cookieOptions);
      removeCookie('user', cookieOptions);
      setIsLoggedIn(false);
      await changeLanguage(defaultLanguage);
      if (redirect) {
        navigate(RoutePaths.SIGN_IN);
      }
    },
    [defaultLanguage, changeLanguage, navigate, removeCookie]
  );

  const savePreferences = useCallback(
    async (preferences: UserPreferences) => {
      try {
        const data: UserPreferences = {
          avatar_url: preferences.avatar_url || '',
          email_notifications: preferences.email_notifications || false,
          language: preferences.language || defaultLanguage,
          sms_notifications: preferences.sms_notifications || false,
          phone_number: preferences.phone_number || '',
          terms_and_conditions: preferences.terms_and_conditions || false,
          volume_enabled: preferences.volume_enabled || false
        };
        await userApi.updateSettings(data);
      } catch (error) {
        console.log(error);
        await logout();

        return;
      }
    },
    [defaultLanguage, userApi, logout]
  );

  const updatePreferences = useCallback(
    async (preferences: UserPreferences) => {
      const updatedUser = {
        ...user,
        preferences: preferences
      } as User;

      await Promise.all([savePreferences(preferences), changeLanguage(preferences.language)]);
      setUserToken(updatedUser);
      setUser(updatedUser);
    },
    [user, savePreferences, changeLanguage, setUserToken]
  );

  const loginWithTokens = useCallback(
    async (access_token: string, id_token: string) => {
      try {
        const { user } = await initApp(id_token);
        setIdentityToken(id_token);
        setAccessToken(access_token);

        if (user.preferences?.language) {
          await changeLanguage(user.preferences.language);
        }

        setUserToken(user);
        setUser(user);
        setIsLoggedIn(true);

        localStorage.removeItem('welcome_video_played');

        return user;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [setAccessToken, setIdentityToken, changeLanguage, setUserToken]
  );
  const login = useCallback(
    async (username: string, password: string) => {
      // const response = await
      const response = await authApi.oAuthLogin({
        username,
        password
      });
      const { access_token, id_token } = response.data;

      return loginWithTokens(access_token, id_token);
    },
    [loginWithTokens, authApi]
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        updatePreferences,
        login,
        loginWithTokens,
        logout,
        isLanguageModalOpen,
        setIsLanguageModalOpen,
        setAccessToken,
        setIdentityToken
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
