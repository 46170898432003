import { createContext, PropsWithChildren, type FC, useState, useCallback } from 'react';

import { type Activity } from './types';

type ActivityContextType = {
  showModal: boolean;
  setShowModal: (val: boolean, preAuthenticate?: boolean) => void;
  activityItem: Activity | null;
  setActivityItem: (newItem: Activity | null) => void;
  nextActivityItem: Activity | null;
  setNextActivityItem: (nextItem: Activity | null) => void;
  preAuthenticate: boolean;
  setPreAuthenticate: (val: boolean) => void;
};

export const ActivitiesContext = createContext<ActivityContextType>({
  showModal: false,
  setShowModal: () => null,
  activityItem: null,
  setActivityItem: () => null,
  nextActivityItem: null,
  setNextActivityItem: () => null,
  preAuthenticate: false,
  setPreAuthenticate: () => null
});

export const ActivitiesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showModal, _setShowModal] = useState(false);
  const [activityItem, setActivityItem] = useState<Activity | null>(null);
  const [nextActivityItem, setNextActivityItem] = useState<Activity | null>(null);
  const [preAuthenticate, setPreAuthenticate] = useState<boolean>(false);

  const setShowModal = useCallback((value: boolean, preAuthenticate = false) => {
    _setShowModal(value);
    setPreAuthenticate(preAuthenticate);
  }, []);

  return (
    <ActivitiesContext.Provider
      value={{
        showModal,
        activityItem,
        setActivityItem,
        nextActivityItem,
        setNextActivityItem,
        setShowModal,
        preAuthenticate,
        setPreAuthenticate
      }}>
      {children}
    </ActivitiesContext.Provider>
  );
};
